exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keynote-speaking-js": () => import("./../../../src/pages/keynote-speaking.js" /* webpackChunkName: "component---src-pages-keynote-speaking-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-results-js": () => import("./../../../src/pages/results.js" /* webpackChunkName: "component---src-pages-results-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

